//=======================================
// Columnas
//=======================================

$cant-col: 12; //Cantidad de columnas
$col-width: 8.3; //Ancho de columnas

//=======================================
// Media Queries
//=======================================

$media-xs: 'min-width : 20em'; // 320px
$media-s: 'min-width : 22em'; // 360px
$media-m: 'min-width: 48em'; // 768px
$media-l: 'min-width: 64em'; // 1024px
$media-lm: 'min-width: 68.75em'; // 1100px
$media-lg: 'min-width: 80em'; // 1280px
$media-xl: 'min-width: 83.75em'; // 1340px

//=======================================
// Colores
//=======================================

$CFFF:#FFFFFF;
$C000:#000000;
$C7B1:#9FA7B1;
$C0A4:#2C60A4;
$C7B0:#4A47B0;
$C9D5:#C169D5;
$C7EB:#2DA7EB;
$C94B:#29394B;
$CD93:#6C8D93;
$CCDC:#DCDCDC;
$CA8B:#6D7A8B;
$CF84:#617F84;
$C6D9:#CFD6D9;
$C7C5:#ACB7C5;
$C9D4:#D6E9D4;
$C96E:#39D96E;
$C745:#28a745;
$C17F:#00C17F;
$C2B8:#17a2b8;
$C2EF:#0C92EF;
$C107:#ffc107;
$CE67:#FF9E67;
$CF31:#F02F31;
$C16B:#F5516B;
$CC79:#FB2C79;
$C960:#DD4960;
$CD38:#2F2D38;
$C3D8:#5ac5e8;
$C012:#1E94EC;
$A046:#0076cf;

//=======================================
// Border-radius
//=======================================

$B5: 5px;
$B10: 10px;
$B15: 15px;
$B20: 20px;

//=======================================
// Shadow
//=======================================

$Shadow: 0px 4px 9px rgba(0, 0, 0, 0.1);
