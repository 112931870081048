.configurator {
  // shadow for left side of menu
  box-shadow: -15px 4px 26px rgba(0, 0, 0, 0.25); // Making configurator column to overlap the header
  position: absolute;
  top: -68px; // --> because the header width is 68px
  right: 0;
  width: 440px;
  height: 100%;
  background-color: white;

  overflow-y: auto;
  overflow-x: hidden;

  // border-left: 2px solid $gray-300; removing border
  .title-bar {
    border-bottom: 1px solid $gray-300;
    height: 200px;
    //background-color: #6d7a8b;
    background: rgb(76, 223, 252);
    //background: linear-gradient(90deg, rgba(76,223,252,1) 1%, rgba(68,180,244,1) 82%, rgba(60,149,236,1) 100%);
    //background: linear-gradient(90deg, rgba(76,223,252,1) 1%, rgba(68,180,244,1) 57%, rgba(60,149,236,1) 100%);
    background: linear-gradient(90deg, rgba(76, 223, 252, 1) 1%, rgba(68, 180, 244, 1) 40%, rgba(60, 149, 236, 1) 100%);
    margin-right: 0px;
    margin-left: 0px;
    padding: 0 12px;
  }

  .nopadding {
    padding: 0;
  }

  .nomargin {
    margin: 0;
  }

  .confsection {
    padding: 1rem 31px;
    border-bottom: 1px solid $gray-300;

    .config-label {
      /* form contents */

      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 25px;
      text-transform: uppercase;
      /* identical to box height, or 138% */

      /* Global/Tools bg */

      color: #6d7a8b;
    }

    .parent-label {
      /* padding-bottom: 15px;*/
    }
  }

  .remove-button {
    padding: 1rem 31px;
  }
}

.border-right {
  border-bottom: 1px solid $gray-300;
}

.selected-products-title {
  margin: auto;
  font-weight: 500;
}

.editable {
  cursor: pointer;
}

.multiline-input {
  width: 100%;
  height: 180px;
  margin-top: 10px;
  /* Global/Tools bg */

  background: #6d7a8b !important;
  box-shadow: inset 0px 6px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px 23px 23px 23px;
  color: #ffffff !important;
}

.input-title-hiddable {
  background: #6d7a8b !important;
  box-shadow: inset 0px 6px 0px rgba(0, 0, 0, 0.2) !important;
  border-radius: 23px;
  color: #ffffff !important;

  width: 270px;
  height: 31px;
}

.input-title-hiddable.disabled {
  opacity: 0.7;
}

.input-title {
  margin-right: 0.5rem;
  width: 50%;
}

.radio-container {
  display: flex;
  flex-direction: column;
}

.link-input {
  margin-top: 10px;
}

.upload-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 600px;
}

.uploaded-images-container {
  display: flex;
  height: 590px;
  flex-wrap: wrap;
  overflow-y: scroll;
  padding-left: 25px;

  .image-container {
    width: 230px;
    height: 200px;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin-right: 9px;
    margin-bottom: 9px;

    .image {
      position: relative;
      left: 0px;
      right: 0px;
      top: 0px;
      bottom: 0px;
      //height: 200x;
    }

    .image.hover {
      /* Global/Tools bg */
      border: 2px dashed #39404a;
      box-sizing: border-box;
      border-radius: 10px;
      //width: 252px;

      .save-button {
        position: absolute;
        left: 6%;
        top: 35%;
        z-index: 1;
        width: 100px;
      }

      .save-button-trial {
        position: absolute;
        left: 22%; /* Set left position to 50% */
        top: 35%; /* Set top position to 50% */
        z-index: 1;
        width: 100px;
      }

      .remove-button {
        position: absolute;
        top: 35%;
        left: 62%;
        z-index: 1;
      }

      .save-button-video {
        position: absolute;
        left: 6%;
        top: 20%;
        z-index: 1;
        width: 100px;
      }

      .save-button-video-trial {
        position: absolute;
        left: 22%; /* Set left position to 50% */
        top: 20%; /* Set top position to 50% */
        z-index: 1;
        width: 100px;
      }

      .remove-button-video {
        position: absolute;
        top: 20%;
        left: 62%;
        z-index: 1;
      }
    }



    .up-image-wrapper {
      width: 220px;
      /* Set to your desired width */
      height: 145px;
      /* Set to your desired height */
      position: relative;
      /* Position context for the image */
      left: 2%;
      /* Replicate your original positioning */
      top: 3%;
      /* Replicate your original positioning */
      border-radius: 5px;
      /* Round the corners of the container */
      overflow: hidden;
      /* Hide the overflow to ensure the image doesn't spill out */
    }

    .up-image {
      width: auto;
      /* Allow the width to adjust proportionally */
      height: 100%;
      /* Set height to 100% of the container */
      position: absolute;
      /* Position absolutely within the container */
      left: 50%;
      /* Center the image */
      transform: translateX(-50%);
      /* Centering trick */
    }

    .selected-video {
      width: 100%;
      height: 100%;
      object-fit: contain;
      /* Use 'cover' to fill the entire container */
    }

    .image-name {
      position: relative;
      top: 5%;

      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 25px;
      /* identical to box height, or 191% */

      text-align: center;

      /* Global/Tools bg */

      color: #6D7A8B;
      text-transform: uppercase;
    }
  }
}

.component-title {
  //left: 31px;
  top: 79px;
  line-height: 36px;

  font-size: 42px;
  font-style: italic;

  p {
    color: #ffffff;
    font-size: 35px;
    font-family: Lato;
    font-weight: 300;

  }
}

// Estilos del Toggle
.custom-toggle-slider {
  background: #6d7a8b;
  box-shadow: inset 0px 6px 0px rgba(0, 0, 0, 0.2);
  height: 31px;
  width: 64px;
}

.custom-toggle input:checked+.custom-toggle-slider:before {
  background: #39d96e;
  height: 23px;
  width: 23px;
  transform: translateX(2.1rem);
  left: 2px;
}

.custom-toggle-slider:before {
  background: #f5516b;
  height: 23px;
  width: 23px;
  bottom: 3px;
  left: 5px;
}

.toggle-with-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custom-dropdown {
  width: 270px;
  height: 31px;

  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  padding-left: 5px;
  padding-right: 5px;
  /* identical to box height, or 138% */
  color: #ffffff;
  /* Global/Tools bg */
  background: #6d7a8b;
  box-shadow: inset 0px 6px 0px rgba(0, 0, 0, 0.2);
  border-radius: 23px;
}

.modern-radio-container {
  margin: 10px 0;
  display: flex;
  cursor: pointer;
  user-select: none;
  height: 31px;
  justify-content: space-between;
  width: 140px;
}

.radio-outer-circle {
  width: 31px;
  height: 31px;
  left: 0px;
  top: 0px;

  /* Global/Tools bg */

  background: #6d7a8b;
  box-shadow: inset 0px 6px 0px rgba(0, 0, 0, 0.2);
  border-radius: 23px;
}

.radio-inner-circle {
  position: relative;
  border-radius: 23px;
  width: 23px;
  height: 23px;
  left: 4px;
  top: 4px;

  /* buttons/ok - on */

  background: #39d96e;
  /* form icon shadow */

  box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.unselected {
  border: 2px solid #666;
}

.unselected-circle {
  width: 0;
  height: 0;
}

.helper-text {
  display: flex;
  align-self: center;
  color: #666;
  text-transform: uppercase;
}

@import "./update-content-section/style.scss";