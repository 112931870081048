@import '../common/variables';
.buttonComponent {
    padding: 7px 14px;
    border-radius:  $B10;
    text-transform: uppercase;
    font-weight: 700;
    background-color:  $C6D9;
    color:  $C000;
    -webkit-box-shadow: 0px 2px 0px 0px  $C7B1;
    box-shadow: 0px 2px 0px 0px  $C7B1;
    &:hover {
        background-color:  $C745;
    }
    &.edit {
        background-color:  $C96E;
        color:  $CFFF;
        -webkit-box-shadow: 0px 2px 0px 0px  $C17F; 
        box-shadow: 0px 2px 0px 0px  $C17F;
        margin-left: 8px;
        border: none;
        &:hover {
            background-color:  $C17F;
        }
    }
    &.preview {
        background-color:  $C3D8;
        color:  $CFFF;
        -webkit-box-shadow: 0px 2px 0px 0px  $CF84;
        box-shadow: 0px 2px 0px 0px  $CF84;
        margin-left: 8px;
        &:hover {
            background-color:  $C3D8;
        }
    }
    &.duplicate {
        background-color:  $C012;
        color:  $CFFF;
        -webkit-box-shadow: 0px 2px 0px 0px  $CF84;
        box-shadow: 0px 2px 0px 0px  $CF84;
        margin-left: 8px;
        &:hover {
            background-color:  $C012;
        }
    }
    &.rename {
        background-color: $C0A4 ;
        color:  $CFFF;
        -webkit-box-shadow: 0px 2px 0px 0px  $CF84;
        box-shadow: 0px 2px 0px 0px  $CF84;
        margin-left: 8px;
        &:hover {
            background-color:  $C0A4;
        }
    }
    &.default {
        background-color:  $C7C5;
        color:  $CFFF;
        -webkit-box-shadow: 0px 2px 0px 0px  $CA8B;
        box-shadow: 0px 2px 0px 0px  $CA8B;
        &:hover {
            background-color:  $CA8B;
        }
    }
    &.upload {
        margin-right: 10px;
        background-color:  $C2EF;
        color:  $CFFF;
        -webkit-box-shadow: 0px 2px 0px 0px  $C0A4;
        box-shadow: 0px 2px 0px 0px  $C0A4;
        .--globalLoaders & {
            margin-right: 20px;
        }
        &:hover {
            background-color:  $C0A4;
        }
    }
    &.delete {
        background-color:  $C16B;
        color:  $CFFF;
        -webkit-box-shadow: 0px 2px 0px 0px  $C960;
        box-shadow: 0px 2px 0px 0px  $C960;
        margin-left: 8px;
        &:hover {
            background-color:  $C960;
        }
    }
    &.animation {
        margin-right: 10px;
        background-color:  $C7C5;
        color:  $CFFF;
        -webkit-box-shadow: 0px 2px 0px 0px  $CA8B;
        box-shadow: 0px 2px 0px 0px  $CA8B;
        &:hover {
            background-color:  $CA8B;
        }
    }
}