.wysiwyg-column {
  
  // overflow-y: scroll;
  // background-color: #fcfcfc;

  .components-container {
    padding: 5px;
    overflow-y: auto;
    height: 100%;
    width: 109%; /* Modify as needed */
  }

  .speaker {
    width: 60%;
    height: 22px;
    display: flex;
    align-self: center;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    background-color: black;
  }

  .main-card {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    margin-bottom: 10rem;
    box-shadow: 0px 20px 8px rgba(0, 0, 0, 0.1);
    border: 12px black ridge;
    border-radius: 53px;
    position: fixed; /* Make the main-card position fixed */
    top: 100; /* Position the main-card at the top of the viewport */
    left: 29%; /* Position the main-card horizontally at the center */
    width: 402px; /* Adjust width as needed */
    height: 85vh; /* Set the height to 100% of the viewport height */
  

    .card-header:first-child {
      border-radius: 40px;
    }
  }

  .wysiwyg-item {
    width: 370px;
    .show-border {
      border: 1px solid #b02f58; // TODO move me to pallete
    }
  }
  .wysiwyg-selected {
    border: 2px dashed #6d7a8b;
    box-sizing: border-box;
    border-radius: 11px;
  }

  .droppable {
    height: auto; // change to auto to fix blocking adding items to bottom but this break empty state to be aligned at the middle
    width: 95%; // modify this to make scroll be outside (child)
    //min-height: 670px;
    min-height: 95%;
  }

  .phone-empty {
    /* Paragraph big */

    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 45px;
    line-height: 46px;
    /* or 102% */

    text-align: center;
    letter-spacing: -0.9px;

    color: #9fa7b1;

    // center text
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 150px;
  }

  .footer-icons {
    border-radius: 0 0 40px 40px;
    height: 75px;
    background-color: white;
    border-top: none;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 52px;
    }
  }
}

@media screen and (max-width: 1450px) {
  .wysiwyg-column .main-card {
    left: 32%;
    /* Other properties, if needed */
  }
}