.single_collection {
    background-color: #FFF;
    margin: 0 0.5rem 0.5rem auto;
    position: relative;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
    width: 240px;
    height: 175px;
}

.single_collection img {
    padding: 0.4rem 0.4rem 0.2rem;
    width: 100%;
    height: 82%;
    border-radius: 10px;
}

.single_collection h3 {
    color: #6D7A8B;
    text-align: center;
    font-family: Lato, sans-serif;
    font-size: 1.0rem;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.single_collection p {
    color: #ACB7C5;
    text-align: center;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    padding-bottom: 2px;
}

.single_collection_button_add {
    cursor: pointer;
    border: none;
    border-radius: 10px;
    background: #39D96E;
    box-shadow: 0px -2px 0px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 9px 0px rgba(0, 0, 0, 0.10);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 40px;
    padding: 15px 26px 18px 29px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: #FFF;
    font-family: Lato, sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.6px;
}

.single_collection_button_remove {
    cursor: pointer;
    border: none;
    border-radius: 10px;
    background: #F5516B;
    box-shadow: 0px -2px 0px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 9px 0px rgba(0, 0, 0, 0.10);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 40px;
    padding: 15px 26px 18px 29px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: #FFF;
    font-family: Lato, sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.6px;
}