@import "../common/variables";

.createNotification {
  margin-bottom: 46px;
  .buttonComponent {
    &.edit {
      background-color: $C96E;
      color: $CFFF;
      -webkit-box-shadow: 0px 2px 0px 0px $C17F;
      box-shadow: 0px 2px 0px 0px $C17F;
      margin-right: 32px;
      &:hover {
        background-color: $C17F;
      }
    }
  &.default {
    border: none;
    margin-right: 32px;
    &:hover {
      background-color: $CA8B;
    }
  }
}
}