iframe {
  border: 0;
}

figcaption,
figure,
main {
  display: block;
}

main {
  overflow: hidden;

  background-color: #e5e5e5;
  height: 100%;
  padding: 73px;
  display: flex;
  flex-direction: column;
}
