.update-content-modal {
  max-width: 80%;
  height: 781px;

  .title-row {
    padding: 1rem;
  }
  .form-container-row {
    // border: 1px solid;
  }
  .form-container-col {
    padding: 0;
  }
}

.update-image-modal {
  max-width: 80%;
  height: 781px;

  .title-row {
    padding: 1rem;
  }
  .subtitle-row {
    padding-bottom: 1rem;
    padding-left: 2rem;
    font-family: Lato;
    font-weight: bold;
    color: #6c7a8a;
  }
  .form-container-row {
    // border: 1px solid;
  }
  .form-container-col {
    padding: 0;
  }
}

.optionsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.optionsContainerScroll {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 17px;
}

.optionsContainerTopConfig {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 15px;
}


.option {
  margin-top: 10px;
  margin-bottom: 10px;
}

input[type="color"] {
  -webkit-appearance: none;
  background: #6d7a8b;
  box-shadow: inset 0px 6px 0px rgba(0, 0, 0, 0.2);
  border-radius: 23px;
  width: 64px;
  height: 31px;
}
input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}
input[type="color"]::-webkit-color-swatch {
  border-radius: 23px;
}

input[type="file"] {
  display: none;
}

.custom-input-color i {
  position: absolute;
  visibility: hidden;
}

.custom-input-color {
  .fa {
    font-size: 22px;
    color: #acb7c5;
  }
  margin-right: -12px;
}

.custom-input-color input {
  touch-action: manipulation;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.icon {
  padding-left: 40px;
  min-width: 40px;
}

.modal-title {
  /* modal title */

  font-family: Lato;
  font-style: italic;
  font-size: 40px;
  line-height: 32px;
  /* identical to box height, or 80% */

  letter-spacing: -0.9px;

  /* Global/Tools bg */

  color: #6d7a8b;
}

.modal-dialog.update-content-modal.modal-lg {
  .modal-content {
    height: 781px;
    background: linear-gradient(90deg, #dcdcdc 50%, #f5f5f5 50%);
  }
}

.modal-dialog.update-image-modal.modal-lg {
  .modal-content {
    height: 655px;
    background: #dcdcdc;
    align-content: center;
  }
}

.modal-dialog.update-content-modal.modal-lg2 {
  .modal-content {
    height: 781px;
    width: 1070px;
    background: linear-gradient(90deg, #dcdcdc 50%, #f5f5f5 50%);
  }
}

.modal-dialog.update-content-modal.modal-lg3 {
  .modal-content {
    height: 781px;
    width: 1070px;
    background: linear-gradient(90deg, #dcdcdc 50%, #f5f5f5 50%);
  }
}

.modal-dialog.update-image-modal.modal-lg2 {
  .modal-content {
    height: 655px;
    width: 1070px;
    background: #dcdcdc;
    align-content: center;
  }
}

.modal-dialog.update-image-modal.modal-lg3 {
  .modal-content {
    height: 700px;
    width: 1070px;
    background: #dcdcdc;
    align-content: center;
  }
}

.modal-left-container {
  background: #dcdcdc;
}

.modal-right-container {
  background: #f5f5f5;
}

.end-shadow {
  width: 553px;
  height: 12px;
  position: absolute;
  left: 2.01%;
  right: 53.57%;
  top: 89.5%;
  bottom: 8.96%;

  background: linear-gradient(
    360deg,
    #c4c4c4 -37.5%,
    rgba(196, 196, 196, 0) 80%
  );
  mix-blend-mode: multiply;
}

.items-qty {
  position: absolute;
  left: 18.12%;
  right: 65.78%;
  top: 93.09%;
  bottom: 3.71%;

  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 19px;
  line-height: 25px;
  /* identical to box height, or 131% */

  text-align: center;
  text-transform: uppercase;

  color: #6d7a8b;
}

.items-qty-selected {
  position: absolute;
  left: 71.93%;
  right: 10.12%;
  top: 93.09%;
  bottom: 3.71%;

  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 19px;
  line-height: 25px;
  /* identical to box height, or 131% */
  width: 226px;

  text-align: center;
  text-transform: uppercase;

  color: #000000;
}

.add-all {
  position: absolute;
  left: 35.1%;
  right: 53.98%;
  top: 93.09%;
  bottom: 3.71%;

  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 25px;
  /* identical to box height, or 131% */

  text-align: center;
  text-decoration-line: underline;
  text-transform: uppercase;

  /* Global/ok dark */

  color: #00c17f;
}

.clear-all {
  position: absolute;
  left: 91%;
  right: 4.26%;
  top: 93.09%;
  bottom: 3.71%;

  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 25px;
  /* identical to box height, or 131% */

  text-align: center;
  text-decoration-line: underline;
  text-transform: uppercase;

  /* buttons/no - off */

  color: #f5516b;
}
