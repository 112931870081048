.design-header {
  background-color: $gray-300;
  .navbar {
    padding-top: 0;
    padding-bottom: 0;
  }
  .nav-link {
    color: $gray-900;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    &.active {
      color: $teal;
    }
    &:hover {
      color: $teal;
    }
  }
}
