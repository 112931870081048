// $gray-300: #dee2e6 !default;
// $gray-600: #b1b1aa !default;

.main-menu {
  height: 68px;
  background: linear-gradient(90deg,
      #8e47cb -7.73%,
      #8e47cb -7.72%,
      #1e94ec 62.66%,
      #00e4ff 109%);
  box-shadow: inset 0px -12px 11px -9px rgba(0, 0, 0, 0.15);

  &.active {
    border-radius: 3px 3px 0px 0px;
    text-decoration: underline;
    text-decoration-color: #3cecf7;
  }

  &:hover {
    color: #3cecf7;
  }

  .logo {
    position: absolute;
    width: 115px;
    height: 50px;
    left: 32px;
    top: 5px;
    font-family: Lato;
    font-style: italic;
    font-weight: bold;
    font-size: 31.38px;
    line-height: 51px;
    /* or 162% */

    letter-spacing: -2.54251px;

    color: #ffffff;

    text-shadow: 0px 0px 35px rgba(0, 234, 255, 0.4);
  }

  .left-options {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;

    position: absolute;
    width: 700px;
    height: 37px;
    left: 169px;
    top: 18px;

    text-transform: uppercase;

    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 32px;
    /* identical to box height, or 202% */

    letter-spacing: 2.6px;

    color: #ffffff;
  }

  .right-options {
    /* Header Nav Right */

    /* Auto Layout */

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;

    position: absolute;
    width: 290px;
    height: 33px;
    right: 0px;
    top: 18px;

    text-transform: uppercase;

    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 32px;
    /* identical to box height, or 202% */

    letter-spacing: 2.6px;

    color: #4f4f4f;

    /* New styles for right alignment */
    justify-content: flex-end;
    /* Align items to the right */
    flex-grow: 1;
    /* Allow the container to grow as needed */
  }
}

.op {
  cursor: pointer;
  margin-right: 25px;

  .underline {
    height: 4px;
    width: 95%;
    background: linear-gradient(90deg, #00ffd1 0%, #00f0ff 100%);
  }

  .disabled {
    color: rgba(195, 191, 191, 0.891);
  }
}