.dnd-sortable-tree_simple_wrapper {
  margin-bottom: -30px;
}
.treeCollection {
  /*margin: 0 0.5rem 0.5rem auto;*/
  margin: 0;

  cursor: pointer;
  border-radius: 10px;
  width: 95%;
  height: 75px;
 /*transition: none !important;*/
}

.treeCollection h3 {
  color: #6d7a8b;
  text-align: center;
  font-family: Lato, sans-serif;
  font-size: 12em;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  position: absolute;
  bottom: 0;
  width: 100%; /* Ensure it spans the width of the container */
  margin-bottom: 0; /* Optional: Adjust as needed */
}
.treeCollection .product_name {
  color: #6d7a8b;
  text-align: left;
  font-family: Lato, sans-serif;
  font-size: 1.0em;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  position: relative;
  padding-left: 25px;
  padding-top: 17px;
  width: 100%; /* Ensure it spans the width of the container */
  margin-bottom: 0; /* Optional: Adjust as needed */
}

.imgTree {
  width: 100%;
  height: 100%;
}

.dnd-sortable-tree_simple_tree-item-collapse_button {
  display: none !important;
  /*transition: none !important;*/
}



.treeCollection img {
  /*padding: 0.4rem 0.4rem 0.2rem;
  border-radius: 10px;
  */
  width: 100%;
  height: 82%;
  /*
  width: 105%;
  height: 82%;
  margin-top: -60px;
  margin-left: -29px;
  margin-right: -56px;
  */
}

.dnd-sortable-tree_simple_tree-item {
  padding: 0px !important;;
  background-color: #fff;
  /*margin: 0 0.5rem 0.5rem auto;*/
  /*position: relative;
    cursor: pointer;*/
  border-radius: 10px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  /*width: 240px;*/
  box-sizing: border-box;
  position: relative;
  align-items: top;
  display: flex;
  /*transition: none !important;*/
  height: 50px;
  /*
   width: 95%;
    align-items: center;
    box-sizing: border-box;
    color: rgb(34, 34, 34);
    display: flex;
    position: relative;
    border: 4px solid rgb(222, 222, 222);
    padding: 10px;
    */
}

.dnd-sortable-tree_simple_handle {
  opacity: 0.0 !important;;
  align-self: stretch;
  cursor: pointer;
  width: 0px !important;;
  /*background: url(f
        data:image/svg + xml;utf8,
        <svgxmlns="http://www.w3.org/2000/svg"viewBox="0 0 20 20"width="12"><pathd="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path></svg>
      )
      50% center no-repeat;*/
}
.dnd-sortable-tree_simple_tree-item-collapse_button {
  display: none !important;
  opacity: 0 !important;;
  align-self: stretch;
  transition-behavior: normal;
  transition-duration: 0.25s;
  transition-timing-function: ease;
  transition-delay: 0s;
  transition-property: transform;
  width: 0px !important;;
  transition: none !important;
  /*
  margin-left: -20px;
  background: url(
        data:image/svg + xml;utf8,
        <svgwidth="10"xmlns="http://www.w3.org/2000/svg"viewBox="0 0 70 41"><pathd="M30.76 39.2402C31.885 40.3638 33.41 40.995 35 40.995C36.59 40.995 38.115 40.3638 39.24 39.2402L68.24 10.2402C69.2998 9.10284 69.8768 7.59846 69.8494 6.04406C69.822 4.48965 69.1923 3.00657 68.093 1.90726C66.9937 0.807959 65.5106 0.178263 63.9562 0.150837C62.4018 0.123411 60.8974 0.700397 59.76 1.76024L35 26.5102L10.24 1.76024C9.10259 0.700397 7.59822 0.123411 6.04381 0.150837C4.4894 0.178263 3.00632 0.807959 1.90702 1.90726C0.807714 3.00657 0.178019 4.48965 0.150593 6.04406C0.123167 7.59846 0.700153 9.10284 1.75999 10.2402L30.76 39.2402Z"/></svg>
      )
      50% center no-repeat;*/
  border: 0px !important;;
}

.dnd-sortable-tree_simple_clone {
  /* display: inline-block;*/
  background-color: #fff;
  margin: 0 0.5rem 0.5rem auto;
  /*position: relative;
    cursor: pointer;*/
  border-radius: 10px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  /*width: 240px;*/
  box-sizing: border-box;
  position: relative;
  align-items: center;
  display: flex;
  width: 300px;
  height: 120px;
  opacity: 0 !important;;
  
}

.dnd-sortable-tree_simple_clone > .dnd-sortable-tree_simple_tree-item {
  background-color: #fff;
  margin: 0 0.5rem 0.5rem auto;
  /*position: relative;
    cursor: pointer;*/
  border-radius: 10px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  /*width: 240px;*/
  box-sizing: border-box;
  position: relative;
  align-items: center;
  display: flex;
  width: 300px;
  height: 120px;
  opacity: 0.2 !important;;
  /*
    border-radius: 4px;
    box-shadow: 0 15px 15px 0 rgba(34, 33, 81, 0.1);
    opacity: 0.5;
    */
}

.dnd-sortable-tree_simple_ghost {
  opacity: 0.1;
}
.dnd-sortable-tree_simple_clone,
.dnd-sortable-tree_simple_ghost {
  transition: transform 0.2s ease;
}

.left_container_sortable {
  width: 25%;
  height: 100vh; /* Full height of the viewport */
  overflow-y: auto; /* Enable vertical scrollbar */
  border: none;
  border-radius: 0 10px 10px 0;
  box-shadow: 0px -2px 0px 0px rgba(0, 0, 0, 0.1) inset,
              0px 4px 9px 0px rgba(0, 0, 0, 0.1);
  padding-left: 20px;
  padding-top: 20px;
  background-color: #f5f5f5;
  padding-bottom: 60px; 
}

.right_container_sortable {
  padding-left: 20px;
  padding-top: 20px;
  width: 75%;
  height: 100vh; /* Full height of the viewport */
  overflow-y: auto; /* Enable vertical scrollbar */
  background-color: #dcdcdc;
  border: none;
  
  
}

.drag-handle-menu {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 30px; /* Adjust the value so it doesn't overlap with the remove button */
  cursor: grab;
}


.remove-button-menu {
  background: none; /* Remove button background */
  border: none; /* Remove button border */
  cursor: pointer; /* Change cursor to pointer on hover */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
  display: flex; /* Use flex to center the icon */
  align-items: center; /* Vertically center the icon */
  justify-content: center; /* Horizontally center the icon */
  color: gray; /* Set the icon color */
  font-size: 1.2em; /* Adjust the icon size */
  position: absolute;
  top: 10px; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
  cursor: pointer;
  color: #20252b;
}

.remove-button-menu:hover {
  color: darkgray; /* Change color on hover for visual feedback */
}
.menu_product_found {
  color: #000;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
  text-transform: uppercase;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-right: 1.5rem;
}

.menu_bottom_product_container {
  display: flex;
  align-items: center;
  justify-content: end;
}

.menu_product_container {
  margin-top: 2.0rem;
    margin-right: 1.0rem;
    margin-bottom: 2.8rem;
    margin-left: 2.4rem;
    
}

.left_top_container h2 {
  color: #6D7A8B;
  font-family: Lato, sans-serif;
  font-size: 40px;
  font-style: italic;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: -0.9px;
  margin-bottom: 20px;
}

.left_top_container h6 {
  color: #6D7A8B;
  font-family: Lato, sans-serif;
  font-size: 20px;
  font-style: italic;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: -0.9px;
  
}

.top_container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.left_top_container, .right_top_container {
  flex-basis: 50%; /* Adjust width as needed */
}

.bottom_containers {
  display: flex;
  flex-basis: 100%;
  /* Add any additional styles you need for this container */
}
.scrollable-content {
  width: 100vw;  
  overflow-y: auto;
  overflow-x: hidden; /* Hide horizontal overflow */
  height: calc(85vh + 1.0rem);
  padding: 1.5rem 1rem 2rem 2rem;

}

.scrollable-content div {
  margin-bottom: 1rem; /* Add space between each div */
}

.scrollable-content label {
  display: block; /* Make labels block-level elements for better spacing */
  margin-bottom: 0.5rem; /* Add space between labels and inputs */
}

.scrollable-content input,
.scrollable-content select,
.scrollable-content textarea {
  flex: 1; /* Allow the input to take remaining space */
  width: 90%; /* Make inputs take full width */
  padding: 0.5rem; /* Add padding for better appearance */
  margin-right: 0.5rem; /* Add space between inputs */
}

.scrollable-content span {
  display: block; /* Make spans block-level elements for better spacing */
  margin-top: 0.5rem; /* Add space between input and span */
  font-size: 12px;
}