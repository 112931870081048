.appbar {
  /* Auto Layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 80px;
  padding-left: 2rem;

  div {
    cursor: pointer;
  }

  .home {
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 15px 0px;
  }

  .collections {
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 15px 0px;
  }

  .prod-lists {
    flex: none;
    order: 2;
    flex-grow: 0;
    margin: 15px 0px;
  }

  .prod-details {
    flex: none;
    order: 3;
    flex-grow: 0;
    margin: 15px 0px;
  }

  .cart {
    flex: none;
    order: 4;
    flex-grow: 0;
    margin: 15px 0px;
  }

  .colors {
    flex: none;
    order: 5;
    flex-grow: 0;
    margin: 15px 0px;
  }
}
