#root {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}
@import "./components/modals/style.scss";
@import "./components/card-layout/style.scss";
