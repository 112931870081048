@import "../common/variables";

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85); /* Gray background at 70% opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-content {
  text-align: center;
}

.loading-text {
  font-size: 50x;
  color: white;
  font-weight: 700;
  font-style: normal;
  padding-left: 20px;
  padding-top: 160px;
  display: flex;
  justify-content: flex-start;
  align-items: left;
  text-align: center;
  position: fixed;
  z-index: 9999;
}

.loading-content img {
  max-width: 200px; /* Adjust the size of the loading image as needed */
}

.productList {
  width: 100%;
  // max-width: 1300px;
  padding: 40px;
  &.--checkoutList,
  &.--checkoutBottom,
  &.--checkoutStepper {
    .info__edit {
      .settingsGroup {
        margin-bottom: 32px;
        .settings {
          &.radioCustom {
            .radioCheck {
              width: 98%;
              &.active {
                width: 100%;
                background-image: url(../../img/icon-gear.png) !important;
                background-repeat: no-repeat !important;
                background-position: 95% center !important;
                background-size: 25px !important;
              }
            }
          }
          &.selects {
            .selectComponent.row {
              justify-content: flex-start;
            }
          }
        }
      }
    }
  }
  &.--detailProduct {
    .info__edit {
      .settingsGroup {
        margin-bottom: 32px;
        .settings {
          &.radioCustom {
            .radioCheck {
              width: 98%;
              &.active {
                width: 100%;
                background-image: url(../../img/icon-gear.png) !important;
                background-repeat: no-repeat !important;
                background-position: 95% center !important;
                background-size: 25px !important;
              }
            }
          }
          &.selects {
            .selectComponent.row {
              justify-content: flex-start;
            }
          }
        }
      }
    }
  }
  &.--navbar {
    .info__edit {
      .settings {
        &.radios {
          width: 100%;
          margin-left: 6px;
          margin-bottom: 12px;
          .radioCheck {
            width: calc(50% - 20px);
          }
        }
      }
    }
  }
  &.--header {
    .info__edit {
      .settings {
        &.radios {
          width: 100%;         
          .radioCheck {
            width: calc(50% - 20px);
          }
        }
      }
    }
  }

  .info__edit {
    width: calc(100% - 300px);
    .box {     
      margin-top: 10px;
      width: 130%;
      /*margin-left: -20px;*/
      .paragraph {
        color: $CA8B;
        line-height: 1.5;
      }
      .buttonComponent {
        &.edit {
          margin-left: 0;
        }
      }
    }
    .settingsGroup {
      .title {
       /* margin-bottom: 0;*/      
      margin-left: 20px;
      margin-top: 20px;
      }
      .settings {
        width: 50%;
        padding-left: 20px;

        &.radios {
          .radioCheck {
            .--detailProduct & {
              width: 100%;
            }
            padding: 8px 12px;
            border-bottom: 1px solid $CD93;
            display: flex;
            align-items: center;
            margin: 0 10px;
            cursor: pointer;
            label {
              display: flex;
              width: 100%;
              padding: 5px 0;
              cursor: pointer;
              color: $CA8B;
              margin-top: 5px;
            }
          }
        }
        &.selects {         
          padding-left: 40px;
          width: 50%;
          .selectComponent.row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 24px;
            label {
              color: $CA8B;
            }
          }
        }
      }
    }
    .titles__page {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      margin-bottom: 116px;
      .title {
        &.primary {
          display: inline-flex;
          width: initial;
          margin-bottom: 0;
        }
      }
      .title__listCollection {
        font-size: 18px;
        color: $C2EF;
        font-weight: 700;
        font-style: normal;
        margin-left: 16px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
  .img__edit {
    background-repeat: no-repeat;
    background-size: 336px; //40%
    background-position: center;
    display: flex;
    justify-content: center;
    width: 402px;
    height: 844px;
    // to avoid the phone to overlap the save button
    position: relative;
    left: 30px;
    top:-70px;
  }
  .block__edit {
    background-repeat: no-repeat;
    background-size: 40%;
    background-position: center;
    display: flex;
    justify-content: flex-end;
    width: 402px;
    height: 244px;
  }
}
.componentNav {
  .componentList {
    display: flex;
    gap: 16px;
    .itemList {
      font-size: 18px;
      color: $CA8B;
      font-weight: 700;
      font-style: normal;
      margin-left: 16px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      cursor: pointer;
      &.active {
        color: $C2EF;
      }
    }
  }
}
.featured {
  color: $C2EF;
}

.radioCheck {
  padding: 8px 12px;
  border-bottom: 1px solid $CD93;
  display: flex;
  align-items: center;
  /*margin: 0 10px; */
  cursor: pointer;
  .--globalLoaders &,
  .--globalStyle & {
    width: 98%;
  }
  .themeColor {
    min-width: 90px;
    max-width: 90px;
    height: 25px;
    display: flex;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    margin-right: 10px;
    position: relative;
    overflow: hidden;
    .halfColor {
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      height: 105%;
      width: 55%;
      margin-left: -5%;
      transform: skew(-25deg);
      -webkit-transform: skew(-25deg);
      -moz-transform: skew(-25deg);
      -ms-transform: skew(-25deg);
      -o-transform: skew(-25deg);
    }
  }
  label {
    display: flex;
    width: 100%;
    padding: 10px 0;
    cursor: pointer;
    color: $CA8B;
  }
  input {
    display: flex;
    align-items: center;
    padding: 4px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 31px;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    height: 31px;
    background: $CA8B;
    box-shadow: 0px 6px 0px 0px #00000033 inset;
    cursor: pointer;
    margin-right: 10px;
    &::after {
      content: "";
      background: transparent;
      width: 23px;
      height: 23px;
      border-radius: 50%;
      display: block;
    }
    &:checked {
      justify-content: flex-end;
      &::after {
        background: $C96E;
      }
    }
  }
  &.active {
    label {
      color: $C000;
    }
    .--globalStyle &,
    .--globalLoaders &,
    .--collection & {
      background-image: url(../../img/icon-gear.png) !important;
      background-repeat: no-repeat !important;
      background-position: 95% center !important;
      background-size: 25px !important;
    }
    background: #f8f8f8;
    width: 100%;
    border-radius: $B5;
    z-index: 1;
    -webkit-border-radius: $B5;
    -moz-border-radius: $B5;
    -ms-border-radius: $B5;
    -o-border-radius: $B5;
  }
}
.themeSetting {
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
  .--globalLoaders & {
    justify-content: flex-start;
  }
  .--globalLoaders &,
  .--globalStyle & {
    color: $CA8B;
  }
}
.button {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 7px 14px;
  min-height: 45px;

  &.reset {
    background: #acb7c5;
    color: #ffffff;
    margin-top: 32px;
  }
  &.upload {
    background: #0c92ef;
    color: #ffffff;
    margin-right: 24px;
  }
}

.radioCustom {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 1px;
  &.--colors {
    .--list & {
      width: 50%;
      flex-wrap: nowrap;
      align-items: center;
    }
  }
  &.--line {
    padding-left: 50px;
    width: 50%;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: -25px;
    margin-left: 45px;
    .title {
      margin-bottom: 18px !important;
    }
  }

  .--list & {
    width: 30%;
    margin-bottom: 32px;
  }
  .--colors {
    .radioSlide {
      .--list & {
        border: 0;
      }
    }
  }
  .radioSlide {
    display: flex;
    align-items: center;
    padding-left: 38px; 
    position: relative; /* allows us to position the icon absolutely relative to this container */

    .contentWrapper {
        display: flex;
        align-items: center;
        flex-grow: 1; /* This allows the contentWrapper to take the available space */
    }

    .iconWrapper {
        position: absolute; /* set the icon's position relative to radioSlide */
        top: 50%; /* vertically center */
        right: 10px; /* push it to the right edge, adjust as needed */
        transform: translateY(-50%); /* adjust for perfect vertical centering */
    }
    .--colors & {
      .--list & {
        border: 0;
      }
    }
    &.active {
      .--line & {
        .--list & {
          background: transparent;
        }
      }
      .--list & {
        //width: 100%;
        background: #FFFFFF;

        background-repeat: no-repeat !important;
        background-position: 95% center !important;
        background-size: 25px !important;
      }
    }
    .--list & {
      width: 98%;
      justify-content: flex-end;
      flex-direction: row-reverse;
      padding: 10px 0;
      border-bottom: 1px solid $CD93;
      z-index: 9;
      
      .--colors & {
        border: 0;
      }
    }
    .radioColors {
      display: flex;
      align-items: center;
      padding: 4px;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      width: 60px;
      border-radius: 25px;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      height: 31px;
      background: $CA8B;
      box-shadow: 0px 6px 0px 0px #00000033 inset;
      cursor: pointer;
      margin-right: 10px;
      margin-left: 10px;
      &::after {
        content: "";
        background: $C16B;
        width: 23px;
        height: 23px;
        border-radius: 50%;
        display: block;
      }
      &.active {
        justify-content: flex-end;
        &::after {
          background: $C96E;
        }
      }
    }
  }
}
.--line,
.--colors {
  .radioSlide {
    .--list & {
      border: 0;
    }
  }
}
.contentColors {
  display: flex;
  align-items: center;
  img.colorpick-eyedropper-input-trigger {
    display: none;
  }
  .pickColor {
    background: $CA8B;
    border-radius: 0 20px 20px 0;
    width: 80px;
    height: 32px;
    -webkit-border-radius: 0 20px 20px 0;
    -moz-border-radius: 0 20px 20px 0;
    -ms-border-radius: 0 20px 20px 0;
    -o-border-radius: 0 20px 20px 0;
    box-shadow: 0px 6px 0px 0px #00000033 inset;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 2px 12px;
    &:after {
      content: "";
      width: 10px;
      height: 10px;
      position: absolute;
      right: 20px;
      display: block;
      background-image: url(../../img/arrowSelect.png) !important;
      background-repeat: no-repeat !important;
      background-position: 95% center !important;
      background-size: 10px !important;
    }
  }
  .textColor {
    width: 100px;
    height: 32px;
    border-radius: 20px 0 0 20px;
    text-transform: uppercase;
    padding-left: 10px;
    background: $CA8B;
    color: $CFFF;
    -webkit-border-radius: 20px 0 0 20px;
    -moz-border-radius: 20px 0 0 20px;
    -ms-border-radius: 20px 0 0 20px;
    -o-border-radius: 20px 0 0 20px;
    box-shadow: 0px 6px 0px 0px #00000033 inset;
  }
}

.App {
  background-color: linear-gradient(
    140.35deg,
    #ffffff 3.88%,
    #ffffff 22.03%,
    #d1d1d1 96.12%
  );
}
.--colors {
  .radioSlide {
    &.active {
      .--list & {
        background: transparent;
        background-image: initial !important;
        border: 0;
      }
    }
  }
}
.--line {
  position: relative;
  .radioSlide {
    &.active {
      .--list & {
        background: transparent;
        background-image: initial !important;
        border: 0;
      }
    }
  }
  .line {
    box-shadow: -6px 0px 7px -3px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    width: 10px;
    height: 115%;
    display: block;
    position: absolute;
    left: -10px;
    top: 0;
  }
}

.img-in-phone {
  width: 37%;
  height: 95%;
}

.newLoaderContainer {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-left: 5px;

  .buttonContainer {
    display: flex;
    /* align-self: flex-end; */
    margin-right: -2rem;
  }
}


//@media screen and (min-width: 800px) {
  @media screen and (max-width: 1500px) {
  .productList {    
      .info__edit {
       
        .box {     
          margin-top: 10px;
          width: 112%;
          .paragraph {
            color: $CA8B;
            line-height: 1.5;
          }
          .buttonComponent {
            &.edit {
              margin-left: 0;
            }
          }
        }
      }
      }
}