@import "../common/variables";
.contentCard {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  margin-bottom: 20px;
  :last-child {
    margin-top: -2px;
  }
  .card {
    display: flex;
    flex-direction: row-reverse;
    width: 433px;
    height: 133px;
    overflow: hidden;
    padding: 18px;
    background-color: $CFFF;
    border-radius: $B10;
    margin-bottom: 6px;
    position: relative;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.1);
    justify-content: space-between;
    &:hover {
      .edit {
        &__card {
          display: flex;
          @keyframes change {
            from {
              opacity: 0.5;
            } /* Primer fotograma */
            to {
              opacity: 1;
            } /* Último fotograma */
          }
        }
      }
    }
    .title {
      &__card {
        font-size: 14px;
        margin-bottom: 8px;
        font-weight: 900;
        text-transform: uppercase;
        color: $CA8B;
      }
    }
    .paragraph {
      &__card {
        color: $C7B1;
      }
    }
    .picture {
      &__card {
        display: flex;
        border-radius: $B10;
        margin-left: 19px;
      }
    }
    .edit {
      &__card {
        align-items: center;
        transform: translateX(-14px);
        width: calc(100% + -8px);
        height: 60px;
        position: absolute;
        bottom: 4px;
        background-color: rgba(172, 183, 197, 0.5);
        border-radius: $B10;
        display: none;
        animation: change 0.3s ease-in-out;
      }
    }
  }
}
