@import '../common/variables';

[class*="title"] {
    display: flex;
    width: 100%;
    font-size: 16px;
    font-weight: 300;
    color:  $C000;
}

.title {
    font-size: 32px;
    line-height: 32px;
    font-style: italic;
    margin-bottom: 30px;
    color:  $C7C5;
    &.primary {
        font-size: 41px;
        font-weight: 300;
        color:  $CA8B;
    }
}