.card-container {
  display: flex;
  width: 500px;
  img {
    border-style: dashed;
    box-shadow: 0px 15px 7px -1px rgba(0, 0, 0, 0.15);
  }
}
.card-in-block-container {
  width: 180px;
  // Blocks
  border-radius: 20px;
  background-color: transparent;

  img {
    border-radius: 10px;
    align-self: center;
  }
}

.card-in-phone {
  // Blocks
  background-color: #5f6e7d;
  border-radius: 20px;
  img {
    border-radius: 10px;
    align-self: center;
  }
}

.custom-to {
  border-radius: 15px !important;
  font-family: Lato;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 19px;
  /* identical to box height, or 99% */

  letter-spacing: 0.0999999px;
  text-transform: uppercase;
  opacity: 1 !important;
  color: #ffffff;
  background-color: rgb(76,85,97) ;
  width: 280px;
  height: auto;
  min-height: 280px;
  margin-left: 80px;
  box-shadow: 0px 15px 7px -1px rgba(0, 0, 0, 0.15);

  .react-tooltip-title {
    font-family: Lato;
    font-style: regular;
    font-weight: 900;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    opacity: 1 !important;
    color: #ffffff;  
    margin-left: 0px; 
    margin-right: 0px;  
    margin-top: 10px;  
  }
  .react-tooltip-desc {
    font-family: Lato;
    font-style: regular;
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    opacity: 1 !important;
    color: #ffffff;  
    margin-left: 0px;  
    margin-top: 2px;  
  }
  .react-tooltip-video {
   position: relative;
    opacity: 1 !important;
   height: auto;
   margin-left: 0px; 
    padding-top: -40px; 
    //padding-bottom: 15px;  

  }

}



.custom-to-v2 {
  border-radius: 15px !important;
  font-family: Lato;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 19px;
  /* identical to box height, or 99% */

  letter-spacing: 0.0999999px;
  text-transform: uppercase;
  opacity: 1 !important;
  color: #ffffff;
  background-color: rgb(76,85,97) ;
  width: 280px;
  height: auto;
  min-height: 280px;
  margin-left: 20px;
  
  box-shadow: 0px 15px 7px -1px rgba(0, 0, 0, 0.15);

  .react-tooltip-title {
    font-family: Lato;
    font-style: regular;
    font-weight: 900;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    opacity: 1 !important;
    color: #ffffff;  
    margin-left: 10px;
    margin-top: 5x;  
  }
  .react-tooltip-desc {
    font-family: Lato;
    font-style: regular;
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    opacity: 1 !important;
    color: #ffffff;  
    margin-left: 10px;  
    margin-top: 2px;  
    
  }
  .react-tooltip-video {
   position: relative;
    opacity: 1 !important;
   height: auto;
    margin-left: -30px;  
    padding-top: 5px; 
    padding-bottom: 15px;  
    //padding-right: -50px;
  }
  .react-tooltip-video-payment {
    position: relative;
     opacity: 1 !important;
    height: auto;
     margin-left: 10px;  
     padding-top: 5px; 
     padding-bottom: 15px;  
     //padding-right: -50px;
   }
}

.custom-to-v3 {
  border-radius: 15px !important;
  font-family: Lato;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 19px;
  /* identical to box height, or 99% */

  letter-spacing: 0.0999999px;
  text-transform: uppercase;
  opacity: 1 !important;
  color: #ffffff;
  background-color: rgb(76,85,97) ;
  width: 420px;
  height: 250px;
  min-height: 340px;
  margin-left: -20px;
  box-shadow: 0px 15px 7px -1px rgba(0, 0, 0, 0.15);

  .react-tooltip-title {
    font-family: Lato;
    font-style: regular;
    font-weight: 900;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    opacity: 1 !important;
    color: #ffffff;  
    margin-left: 0px;
    margin-top: 25x;  
  }
  .react-tooltip-desc {
    font-family: Lato;
    font-style: regular;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    opacity: 1 !important;
    color: #ffffff;  
    margin-left: 0px;  
    margin-top: 10px;  
    
  }

  .react-tooltip-video-payment {
    position: relative;
     opacity: 1 !important;
    //height: auto;
    width: 520x;
     margin-left: 0px;  
     margin-top: -50px; 
     //padding-bottom: 15px;  
     //padding-right: -50px;
   }
}

.configurator {
  .configuration-side-title {
    font-family: Lato;
    font-style: regular;
    font-weight: 900;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    opacity: 1 !important;
    color: #ffffff;
    margin-top: 12px;
  }

  .configuration-side-desc {
    font-family: Lato;
    font-style: regular;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    opacity: 1 !important;
    color: #ffffff;
    margin-top: 6px;
  }
}