@import "../common/variables";

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  z-index: 9999;
  &.isOpen {
    display: flex;
    .modal__container {
      max-width: 950px;
      background-color: $CFFF;
      padding: 28px;
      border-radius: $B10;
      position: relative;
      height: auto;
      .row {
        justify-content: space-between;
        margin-bottom: 10px;
        &.body__modal {
          margin-bottom: 0;
        }
      }
      .modal__title {
        display: flex;
        width: 100%;
        color: $CA8B;
        font-size: 32px;
         padding-left: 40px;
      }
      .info__modal {
        display: flex;
        flex-direction: column;
        width: calc(100% - 300px);
        padding-left: 60px;
        padding-right: 0;
        .notification {
          flex-wrap: nowrap;
          justify-content: flex-start;
          transform: translateX(-60px);
          width: calc(100% + 60px);
          margin-bottom: 20px;
          .numbersNotification {
            font-size: 62px;
            line-height: 24px;
            margin-right: 10px;
            color: $C7EB;
            font-weight: 300;
          }
          .title__notification {
            padding-left: 80px;
            font-weight: 400;
          }
          .fullWidth {
            max-width: 100%;
          }
        }
        .form__modal {
          margin-bottom: 20px;
          padding-left: 0px;
          padding-right: 0px;
          label {
            display: flex;
            flex-direction: column;
            color: #6d7a8b;
            font-size: 14px;
            line-height: 24px;
            height: auto;
          }
          .labelText{
            padding: 0;
          }
          .inputComponent {
            background-color: $CA8B;
            box-shadow: 0px 3px 0px 0px #00000033 inset;
            padding: 0 10px;
            margin-bottom: 10px;
            border-radius: $B10;
            font-size: 18px;
            line-height: 25px;
            color: $CFFF;
            .item {
              font-size: 14px;
              color: $C7C5;
              border: none;
              border-style: none;
            }                      
            &.--message {
              height: 245px;
              font-weight: 200;
               padding-left: 5px;
            }
            &.--product {
              color: $C7C5;
            }
            &:focus {
              outline: $C7EB;
            }
            &::placeholder {
              color: $C7C5;
              font-size: 14px;
            }
          }
          .contentProducts {
            align-items: flex-end;
            flex-wrap: nowrap;
            width: 100%;            
            .labelProduct {
              display: flex;
              flex-direction: column;
              flex-grow: 1;
              padding: 0;
            }
            .actionContainer{
              display: flex;
              gap: 30px;
            }
            .textSelect {
              display: flex;
              color: $C7EB;
              line-height: 45px;
              flex-grow: 1;
              padding-left: 25px;
            }

            .--submit {
              padding: 5px 0px;
              background-color: $C7EB;
              box-shadow: none;
              color: $CFFF;
              flex-grow: 1;
              font-weight: 300;
              text-align: center;
              text-transform: uppercase;
              margin-bottom: 12px;
              border: none;
              -webkit-box-shadow: 0px 2px 0px 0px  $C0A4;
              box-shadow: 0px 2px 0px 0px  $C0A4;
              
            }
            .--submit-disabled {
              /* Your styles for the disabled state */
              opacity: 0.25;            
              padding: 5px 0px;
              background-color: $C7EB;
              box-shadow: none;
              color: $CFFF;
              flex-grow: 1;
              font-weight: 300;
              text-align: center;
              text-transform: uppercase;
              margin-bottom: 12px;
              border: none;
              -webkit-box-shadow: 0px 2px 0px 0px  $C0A4;
              box-shadow: 0px 2px 0px 0px  $C0A4;
            }
          }
        }
        .contentSend {
          align-items: flex-end;
          flex-wrap: nowrap;
          margin-bottom: 20px;
          margin-left: 0;
          margin-right: 0;
          
          .labelSend {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            color: #6d7a8b;
            font-size: 14px;
            line-height: 24px;
            .inputComponent {
              background-color: $CA8B;
              box-shadow: 0px 3px 0px 0px #00000033 inset;
              padding: 0 10px;
              margin-bottom: 15px;
              border-radius: $B10;
              font-size: 18px;
              font-weight: 300;
              line-height: 25px;
              color: $CFFF;            
              .item {
                font-size: 14px;
                color: $C7C5;
                border: none;
                border-style: none;
              }              
            }
          }
          .textSelect {
            display: flex;
            color: $CD93;
            text-transform: uppercase;
            line-height: 45px;
            flex-grow: 1;
            padding-left: 25px;
          }
          .--upload {
            background-color: $A046;
            box-shadow: none;
            color: $CFFF;
            border-radius: $B10;
            flex-grow: 1;
            font-weight: 300;
            text-align: center;
            text-transform: uppercase;
            display: flex;
            align-self: stretch;
            justify-content: center;
            border: none;
            padding: 10px 0px;
            box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.1), inset 0px -2px 0px rgba(0, 0, 0, 0.1);
          }
          .--submit {
            background-color: $C9D5;
            box-shadow: none;
            color: $CFFF;
            border-radius: $B10;
            flex-grow: 1;
            font-weight: 300;
            text-align: center;
            text-transform: uppercase;
            display: flex;
            align-self: stretch;
            justify-content: center;
            border: none;
            padding: 10px 0px;
            box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.1), inset 0px -2px 0px rgba(0, 0, 0, 0.1);
          }
          .--submit-disabled {
            /* Your styles for the disabled state */
            opacity: 0.25;
            background-color: $C9D5;
            box-shadow: none;
            color: $CFFF;
            border-radius: $B10;
            flex-grow: 1;
            font-weight: 300;
            text-align: center;
            text-transform: uppercase;
            display: flex;
            align-self: stretch;
            justify-content: center;
            border: none;
            padding: 10px 0px;
            box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.1), inset 0px -2px 0px rgba(0, 0, 0, 0.1);
          }
        }
      }
      .img__modal {
        background-repeat: no-repeat;
        background-size: 85%;
        background-position: bottom right;
        display: flex;
        justify-content: flex-end;
        width: 300px;
        height: 678px;
      }
    }
    .picture__modal {
      display: flex;
      justify-content: flex-end;
      position: absolute;
      z-index: 1;
      right: 5px;
      top: 65px;
      width: 100%;
      .img {
        &.phone {
          width: 32%;
        }
      }
    }
  }
}
.miniCard {
  width: 250px;
  height: auto;
  min-height: 80px;
  display: flex;
  justify-content: space-between;
  background-color: $CFFF;
  border-radius: 5px;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.1);
  padding: 7px;
  overflow: hidden;
  top: 140px;
  right: 60px;
  position: absolute;

  .title__card {
    text-transform: uppercase;
    color: $CD38;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    display: flex;
    //max-width: 90px;
    margin-right: 17px;
    word-wrap: break-word;
  }
  .paragraph__card {
    color: $CD38;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0px;
    //max-width: 90px;
    padding-right: 17px;
    text-align: left;
    padding-top: 5px;
  }

  .img {
    display: block;
    width: 62px;
    height: 70px;
    transform: none;
  }
}
.itemSelected{
  color: $C7EB;
  font-weight: 400;
  font-size: 17px;
  margin-bottom: 0px;
}
.itemSelectedTitle{
  color: $CA8B;
  font-weight: 400;
  font-size: 17px;
  margin-bottom: 0px;
}