body {
  scrollbar-color: red;
}
.market-column-main {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 70vh;

  padding: 0.5rem 1rem 0;
  overflow-y: auto;
}

.market-item {
  padding-bottom: 4px;
  .show-border {
    border: 1px solid #b02f58; // TODO move me to pallete
  }
}

.hide-me {
  opacity: 0.2;
  padding-bottom: 4px;
  + div {
    display: none !important;
  }
}

// Toolbar column
.toolbar {
  margin-top: 10vh;
  width: 260px;

  background: #6d7a8b;
  mix-blend-mode: normal;
  box-shadow: -9.18485e-16px 15px 6px rgba(0, 0, 0, 0.141176);
  border-radius: 18px;
}

// Toolbar column
.toolbar-b {
  /* Global/Tools bg */
  margin-top: 15px;
  height: 84%;
  width: 250px; // modify this to make scroll be outside (parent container)
  background: #6d7a8b;
  mix-blend-mode: normal;
  box-shadow: -9.18485e-16px 15px 6px rgba(0, 0, 0, 0.141176);
  border-radius: 18px;

  .child-container {
    position: relative;
    width: 234px; // modify this to make scroll be outside (child)
    left: 35px;
    top: 21px;
    bottom: 47px;

    overflow-y: auto;
    height: 92%;
  }
}

.home-layout-label {
  position: absolute;
  width: 217px;
  height: 51px;
  left: 126px;
  top: 95px;

  /* Section Title */

  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 51px;
  /* identical to box height, or 124% */

  letter-spacing: -2.4px;

  /* Global/Tools bg */

  color: #6d7a8b;
}

.component-label {
  margin-left: 20px;

  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 32px;
  /* identical to box height, or 190% */

  letter-spacing: 1.6px;

  color: #ffffff;
}
