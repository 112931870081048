.container-modal-product {
  display: flex;
  height: 98%;
}

.left_container {
  width: 50%;
  grid-column: 1 / span 2;
  height: auto;
  background-color: #DCDCDC;
  border: none;
  border-radius: 10px 0 0 10px;
  box-shadow: 0px -2px 0px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 9px 0px rgba(0, 0, 0, 0.10);
}

.right_container {
  width: 50%;
  border: none;
  border-radius: 0 10px 10px 0;
  box-shadow: 0px -2px 0px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 9px 0px rgba(0, 0, 0, 0.10);
  grid-column: 3;
  height: auto;
  background-color: #F5F5F5;
}

.product_container {
  margin-top: 2.7rem;
    margin-right: 1.0rem;
    margin-bottom: 2.8rem;
    margin-left: 1.4rem;
}



.item_container {
  margin-top: 2.7rem;
    margin-right: 1.0rem;
    margin-bottom: 2.8rem;
    margin-left: 1.4rem;
}

.right_container_btn {
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 10px;
  margin-bottom: 20px;
}

.product_container h2 {
  color: #6D7A8B;
  font-family: Lato, sans-serif;
  font-size: 40px;
  font-style: italic;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: -0.9px;
  margin-bottom: 30px;
}

.products {
  overflow-x: hidden;
  height: 550px;
  overflow-y: scroll;
}

.product_list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-left: 5px;
  padding-top: 5px;
}

.bottom_product_container {
  display: flex;
  align-items: center;
  justify-content: end;
}

.bottom_product_container .addToCart,
.bottom_product_container .removeFromCart {
  cursor: pointer;
  padding-top: 1.5rem;
  color: #00C17F;
  font-family: Lato, sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 24.85px;
  text-transform: uppercase;
  text-decoration: underline;
}


.right_container_btn button:nth-child(1) {
  color: #FFF;
  padding: 1px 18px;
  border: none;
  border-radius: 10px;
  background-color: #ACB7C5;
  box-shadow: 0px -2px 0px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 9px 0px rgba(0, 0, 0, 0.10);
}

.right_container_btn button:nth-child(2) {
  color: #FFF;
  border: none;
  padding: 9px 18px;
  border-radius: 10px;
  background: #39D96E;
  box-shadow: 0px -2px 0px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 9px 0px rgba(0, 0, 0, 0.10);
}

.product_found {
  color: #000;
  font-family: Lato, sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 300;
  line-height: 24.85px;
  text-transform: uppercase;
  padding-top: 1.5rem;
  padding-right: 1.5rem;
}