.publish-modal-header {
  display: flex;
  background: #dcdcdc;
  border-radius: 20px 20px 0px 0px;
  justify-content: space-evenly;
  align-items: center;
  width: 500px;
  height: 190px;
  
  .publish-modal-message {
    padding-top: -40px;
    width: 340px;
    height: 100px;
    font-family: Lato;
    font-style: italic;
    font-weight: 300;
    font-size: 22px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #6d7a8b;
  }
}

.publish-modal-header-alert {
  display: flex;
  background: #dcdcdc;
  border-radius: 20px 20px 0px 0px;
  justify-content: space-evenly;
  align-items: center;
  width: 500px;
  height: 190px;

  .publish-modal-message {
    padding-top: 10px;
    width: 350px;
    height: 170px;
    font-family: Lato;
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    display: flex;
    flex-direction: column;
    justify-content: center; // This will vertically center the child divs
    color: #6d7a8b;
  }

  .publish-modal-message > div {
    display: block;
  }

  .publish-modal-message > div > span {
    line-height: 1.5;  // Adjust as needed for alignment
  }
}


.publish-modal-buttons-alert {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  padding-right: 20px;
  padding-bottom: 20px;
}

  .publish-modal-buttons {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.container_span {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1vh; /* Adjust the height to fit your requirements */
}

.centered_span {
  text-align: center;
}

  .col-14 {
  flex: 0 0 00%;
  max-width: 50%; }

  .remove-modal-header {
  display: flex;
  background: #dcdcdc;
  border-radius: 20px 20px 0px 0px;
  justify-content: space-evenly;
  align-items: center;
  width: 430px;
  height: 141px;

  .remove-modal-message {
    width: 273px;
    height: 103px;
    font-family: Lato;
    font-style: italic;
    font-weight: 300;
    font-size: 30px;
    line-height: 26px;
    /* or 88% */
    display: flex;
    align-items: center;

    /* Global/Tools bg */
    color: #6d7a8b;
  }

  .remove-modal-icon {
  }
}

.modal-content {
  border-radius: 20px;
  width: 1245px;
  height: 233px;
}

.remove-modal-buttons {
  display: flex;
  justify-content: flex-end;
  padding-top: 23px;
  padding-right: 21px;
}

.confirm-remove-modal {
  width: 430px;
}
