@import '../common/variables';
.scheduled {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 80px;

    .title {
        font-size: 32px;
        line-height: 32px;
        font-style: italic;
        margin-bottom: 30px;
        color:  $C7C5;
    }
    .message {
        &__card {
            display: flex;
            &.isWarning {
                p{
                    color:  $CF31;
                }
            }
            &.isInfo {
                p{
                    color:  $C2EF;
                }
            }
            &.isOk {
                p{
                    color:  $C17F;
                }
            }
            p{
                font-size: 17px;
                color:  $CA8B;
                margin-left: 6px;
            }
        }
    }
}